import { ContentBlocksDTO } from './ContentBlocksDTO';
import { FooterDTO } from './FooterDTO';
import { HeaderDTO } from './HeaderDTO';
import { PromoBannerDTO } from './PromoBannerDTO';
import { SeoDataDTO } from './SeoDataDTO';
import { IHomepage } from '../interfaces/IHomepage';
import { THomepage } from '../types/THomepage';

export const HomepageDTO = (data: IHomepage): THomepage => ({
  contentTypeUid: 'homepage',
  footer: FooterDTO(data.footer_reference[0]),
  header: HeaderDTO(data.header_reference[0]),
  homePageContentBlock: ContentBlocksDTO(data.home_page_content_block),
  promoBanner: PromoBannerDTO(data.promo_banner_reference[0]),
  seo: SeoDataDTO(data.seo),
  uid: data.uid,
  title: data.title,
});
