import CartBanner from './CartBanner';
import ErrorPage from './ErrorPage';
import Footer from './Footer/Footer';
import Header from './Header';
import Homepage from './Homepage';
import PromoBanner from './PromoBanner';
import StaticContentPage from './StaticContentPage';
import { CartBannerDTO } from '../../DTO/CartBannerDTO';
import { ErrorPageDTO } from '../../DTO/ErrorPageDTO';
import { FooterDTO } from '../../DTO/FooterDTO';
import { HeaderDTO } from '../../DTO/HeaderDTO';
import { HomepageDTO } from '../../DTO/HomepageDTO';
import { PromoBannerDTO } from '../../DTO/PromoBannerDTO';
import { StaticContentPageDTO } from '../../DTO/StaticContentPageDTO';

// TODO: If you add a new component that can be rendered by frontastic with data from contentstack,
//  you need to add a case statement here with the contentTypeUid that returns the new component
//  you're adding.

const ContentstackRenderer = ({ contentTypeUid, data }) => {
  switch (contentTypeUid) {
    case 'cart_banner_parts':
      return <CartBanner data={CartBannerDTO(data)} />;
    case 'home_page_template_parts':
      return <Homepage data={HomepageDTO(data)} />;
    case 'static_content_page_parts':
      return <StaticContentPage data={StaticContentPageDTO(data)} />;
    case 'error_page_parts':
      return <ErrorPage data={ErrorPageDTO(data)} />;
    case 'promo_banner_parts':
      return <PromoBanner data={PromoBannerDTO(data)} />;
    case 'header_parts':
      return <Header data={HeaderDTO(data)} />;
    case 'footer_parts':
      return <Footer data={FooterDTO(data)} />;
    default:
      return <></>;
  }
};

export default ContentstackRenderer;
