import React from 'react';
import Head from 'next/head';
import styles from './StaticContentPage.module.scss';
import { IStaticContentPageProps } from './types/IStaticContentPageProps';
import CLPBrow from '../CLPBrow';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const StaticContentPage = ({ data }: IStaticContentPageProps) => {
  const {
    metaData: { metaDescription, metaImage, metaKeywords },
  } = data;

  return (
    <div data-testid="static_content_page" className={styles.staticContentPage}>
      {data.metaData && (
        <Head>
          {data?.title && <title>{data?.title}</title>}
          {metaDescription && <meta name="description" content={metaDescription} />}
          {metaKeywords && <meta name="keywords" content={metaKeywords} />}
          {metaImage && <meta property="og:image" content={metaImage.url} />}
        </Head>
      )}
      {data.promoBanner && data?.promoBanner && <PromoBanner data={data.promoBanner} />}
      {data?.header && <Header data={data.header} />}
      {data.staticContentBrow && data?.staticContentBrow && (
        <CLPBrow data={data?.staticContentBrow} isStaticContentBrow={true} />
      )}
      <main className="static-content-page-main-content" data-testid="static-content-page-main-content">
        <ContentBlocks data={data.contentBlocks} />
      </main>
      {data?.footer && <Footer data={data.footer} />}
    </div>
  );
};

export default StaticContentPage;
