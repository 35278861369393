import React from 'react';
import Head from 'next/head';
import { HomePageSchema } from 'components/SEO//HomePage';
import { useFormat } from 'helpers/hooks/useFormat';
import { IHomepageProps } from './types/IHomepageProps';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const Homepage = ({ data }: IHomepageProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { metaDescription, metaImage, metaKeywords } = data?.metaData || {};

  const title =
    data.title ||
    formatMessage({
      id: 'meta.title',
      defaultMessage: 'Cameras and photography equipment from Nikon',
    });
  const description =
    metaDescription ||
    formatMessage({
      id: 'meta.desc',
      defaultMessage: 'Find high quality cameras and photography accessories at Nikon',
    });

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {metaImage && <meta property="og:image" content={metaImage.url} />}
      </Head>
      <div data-testid="homepage" className="nikon-homepage">
        {data.promoBanner && <PromoBanner data={data.promoBanner} />}
        <Header data={data.header} />
        <main data-testid="homepage-main-content" className="homepage-main-content">
          <ContentBlocks data={data.homePageContentBlock} />
        </main>
        {data.seo && <HomePageSchema seoData={data.seo} />}
        <Footer data={data.footer} />
      </div>
    </>
  );
};

export default Homepage;
